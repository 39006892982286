import { Route, Routes, useLocation } from "react-router-dom";

import { useEffect } from "react";
import AboutUsPage from "../../Pages/about_us/AboutUsPage";
import AdminIndex from "../../Pages/admin_page/AdminIndex";
import Blogs from "../../Pages/admin_page/blogs/Blogs";
import NewBlog from "../../Pages/admin_page/blogs/NewBlog";
import CareerOpenings from "../../Pages/admin_page/careers/CareerOpenings";
import InternApplications from "../../Pages/admin_page/careers/InternApplications";
import JobApplications from "../../Pages/admin_page/careers/JobApplications";
import AiHubPage from "../../Pages/ai_hub/AiHubPage";
import CareersIndex from "../../Pages/careers/CareersIndex";
import Internship from "../../Pages/careers/Internship";
import ContactUs from "../../Pages/contact_us/ContactUs";
import Home from "../../Pages/home/Home";
import LushaiAnaytics from "../../Pages/lushai_analytics/LushaiAnaytics";
import MizoYoutube from "../../Pages/lushai_analytics/MizoYoutube";
import Services from "../../Pages/services/Services";
import TermsConditions from "../../Pages/terms/TermsConditions";
import BlogsHome from "../../Pages/blogs/BlogsHome";
import VcElection from "../../Pages/lushai_analytics/VcElection";


export default function AppRouter() {

  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])


  return (
    <Routes>
      <Route
        exact
        strict
        path="/"
        element={<Home />}
      />
      <Route
        exact
        strict
        path="/contact"
        element={<ContactUs />}
      />
      <Route
        exact
        strict
        path="/services"
        element={<Services />}
      />
      <Route
        exact
        strict
        path="/terms"
        element={<TermsConditions />}
      />
      <Route
        exact
        strict
        path="/careers"
        element={<CareersIndex />}
      />
      <Route
        exact
        strict
        path="/careers/apply-internship"
        element={<Internship />}
      />
      <Route
        exact
        strict
        path="/admin/intership-applications"
        element={<InternApplications />}
      />
      <Route
        exact
        strict
        path="/admin/job-applications"
        element={<JobApplications />}
      />
      <Route
        exact
        strict
        path="/about-us"
        element={<AboutUsPage />}
      />
      <Route
        exact
        strict
        path="/blogs"
        element={<BlogsHome />}
      />
      <Route
        exact
        strict
        path="/ai-hub"
        element={<AiHubPage />}
      />
      <Route
        exact
        strict
        path="/lushai-analytics"
        element={<LushaiAnaytics />}
      />
      <Route
        exact
        strict
        path="/lushai-analytics/youtube-analytics"
        element={<MizoYoutube />}
      />
      <Route
        exact
        strict
        path="/lushai-analytics/vc-election-2025"
        element={<VcElection />}
      />
      <Route
        exact
        strict
        path="/admin"
        element={<AdminIndex />}
      />
      <Route
        exact
        strict
        path="/admin/manage-career-openings"
        element={<CareerOpenings />}
      />
      <Route
        exact
        strict
        path="/admin/blogs"
        element={<Blogs />}
      />
      <Route
        exact
        strict
        path="/admin/blogs/post"
        element={<NewBlog />}
      />
      
    </Routes>
  )
}